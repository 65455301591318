import React from 'react';
import { memo } from "react";
import '../App.css';
import { Button, Container } from "@mui/material";


import { styled } from '@mui/material/styles';

import VideoViewer from '../components/VideoViewr'


type VideoType = {
  url: string;
  title: string;
  description: string;
};

const videoList: VideoType[] = [
  {
    url: 'https://youtu.be/nNRLHliE9iA',
    title: 'カーボカウント',
    description: '講師：宮崎大学医学部附属病院　柳 恵里先生',
  },
  {
    url: 'https://youtu.be/sk4i49NmEvo',
    title: '糖尿病勉強会',
    description: '講師：宮崎大学医学部　明利 聡瑠先生',
  },
  {
    url: 'https://youtu.be/pAxTrPqCt5o',
    title: 'おまけ',
    description: '2024年サマキャン予告編',
  }
 
];
const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));
//ログインが成功しないとこのページに遷移しないようにする
//ログインが成功したら、このページに遷移するようにする
//ログインが失敗したら、ログインページに戻るようにする


export const Home = memo(() =>  {
  //3つのボタンを表示
  //それぞれのボタンをクリックすると、それぞれの動画が表示される
  //押したボタン番号をstateで管理
  
  const [videoIndex, setVideoIndex] = React.useState(0);

  const myAuthority = sessionStorage.getItem('AUTHORITY');
  if (myAuthority !== 'OKLOGIN') {
    window.location.href = '/login';
  }
  
  return(
  <>
      <header className='header__section'>
        <p className='header__text'>ヤングフェニックス講座</p>
      </header>
    
       <Container maxWidth="md" >
        
          <VideoViewer  url={videoList[videoIndex].url} />
          <Div>{videoList[videoIndex].description}</Div>;
          {/* センタリング　*/}
          <div style={{textAlign: 'center'}}>
          <Button variant="outlined" onClick={() => setVideoIndex(0)}>カーボカウント</Button>
          <Button variant="outlined" onClick={() => setVideoIndex(1)}>糖尿病教室</Button>
          <Button variant="outlined" onClick={() => setVideoIndex(2)}>おまけ</Button>
          </div>
         <div style={{textAlign: 'center'}}>
            <br></br><br></br><br></br>
            <p>Copyright(C)ヤングフェニックスはまゆう会 2024</p>
            </div> 
      </Container>
      
      
      
      
    </>
  );
});
