import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    TextField,
  } from "@mui/material";
  import { memo, useState } from "react";
  import { User } from "../types/User";
  import { useNavigate } from "react-router-dom";
  

  // ログイン認証処理
  // userId, password から判定する
  const  auth = (userId: string, password: string) => {
    console.log(userId);
    console.log(password);

    if (userId === "ypsc2024" && password === "TAKANABE2024") {
        sessionStorage.setItem('AUTHORITY', 'OKLOGIN');
        return true;
    } else {
      sessionStorage.setItem('AUTHORITY', 'OKLOGIN');
      return false;
    }
  };



export const Login = memo(() => {
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const cardStyle = {
        display: "block",
        transitionDuration: "0.3s",
        height: "450px",
        width: "100%", // Modified width to be 100% for mobile devices
        maxWidth: "400px", // Added maxWidth to limit the width on larger screens
        variant: "outlined",
    };

    const onClickLogin = () => {
        console.log("LOGIN");
        auth(userId, password) ? navigate("/home") : navigate("/fail_login");
    };

    return (
        <Box
            display="flex"   
            alignItems="center"
            justifyContent="center"
            padding={2}
        >
            <Card style={cardStyle}>
                <CardHeader title="ログイン" />
                <CardContent>
                    <div>
                        <TextField
                            fullWidth
                            id="username"
                            type="text"
                            label="Username"
                            placeholder="Username"
                            margin="normal"
                            onChange={(e) => setUserId(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            id="password"
                            type="password"
                            label="Password"
                            placeholder="Password"
                            margin="normal"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                </CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        onClick={onClickLogin}
                    >
                        Login
                    </Button>
                </CardActions>
            </Card>
        </Box>
    );
});
  