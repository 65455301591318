import React , { useState } from 'react';

import ReactPlayer from 'react-player/youtube';


import '../App.css';

import { Button, Container } from "@mui/material";
import screenfull from 'screenfull';

import { styled } from '@mui/material/styles';

type VideoViewerProps = {
  url: string;
};


//動画ビューア画面を表示
//propsでurlを受け取る
const VideoViewer = ({url}: VideoViewerProps) => {

  const [isPlaying, setIsPlaying] = useState(false);

  //動画ビューア画面を表示
  return(
    <div className="player__wrapper">
        <ReactPlayer
        className="player"
          url={url}
          controls={true}
          playing={false}
           width={'100%'}
           
        />
        </div>

  );
};


//
// const VideoViewer = () => {
//   const url = "https://youtu.be/nNRLHliE9iA";


//   const [isPlaying, setIsPlaying] = useState(false);


//   //動画ビューア画面を表示
//   return(
//     <div className="player__wrapper">
//         <ReactPlayer
//         className="player"
//           url={url}
//           controls={true}
//           playing={false}
//            width={'100%'}
           
//         />
//         </div>

//   );
// };





export default VideoViewer;