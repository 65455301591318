import { memo } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
export const FailedLogin = memo(() => {
  const cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    height: "300px",
    width: "400px",
    variant: "outlined",
  };
  const navigate = useNavigate();

  function onClickLogin(): void {
    navigate("/login");
  }
 
  return (
    <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    padding={2}
  >
    <Card style={cardStyle}>
      <CardHeader title="ログイン失敗" />
      <CardContent>

      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={onClickLogin}
        >
          Loginに戻る
        </Button>
      </CardActions>
    </Card>
  </Box>
  );
});
